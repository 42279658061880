import React from "react";
import { Pagination as SemanticPagination } from "semantic-ui-react";

function Pagination({ currentPage, itemsPerPage, total, setCurrentPage }) {
  if (total > itemsPerPage)
    return (
      <SemanticPagination
        style={{ marginTop: "1em" }}
        activePage={currentPage}
        totalPages={Math.ceil(total / itemsPerPage)}
        boundaryRange={1}
        siblingRange={1}
        firstItem={null}
        lastItem={null}
        onPageChange={(e, { activePage }) =>
          setCurrentPage(activePage)
        }
      />
    );
  else return null;
}

export default Pagination;
