import React, { Component } from "react";
// import ReactNotification from "react-notifications-component";
// import { Loader, Dimmer } from "semantic-ui-react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import "semantic-ui-css/semantic.min.css";
import "rsuite/dist/styles/rsuite-default.css";
// import "../components/components.scss";

// import { store } from "../containers/CRM.jsx";

import { signUpAction, signInAction, logOutAction } from "../actions/auth";
import { getUsers } from "../actions/user";
import { removeError, removeSuccessMsg } from "../actions/error";
// import Users from '../components/Users/users'
import Home from "../components/home.jsx";
import Notification from "../components/Notification";
// import Droparea from "../components/Dropzone/droparea";
// import Authorization from "../hocs/withLogged";
import { AuthForm } from "../components/authForm";

// WAREHOUSE
import Stocks from "../components/warehouse";
// import Inventorycard from "../components/Inventorycard/inventorycard";
import InventoryCountList from "../components/warehouse/InventoryCountList";
import Warehouse from '../components/WarehouseSettings/warehouse'
// INBOUND SHIPMENT

import CreateInboundShipment from "../components/InboundShipment/CreateIS/";
import EditViewInboundShipment from "../components/InboundShipment/EditViewIS/";
import ChangeStockStorageLocation from "../components/changestocklocation";
import Inboundshipments from "../components/InboundShipment/ListIS/";

// INBOUND PARCEL

import Createinboudparcel from "../components/Inboundparcel/CreateIS/";
import Inboundparcel from "../components/Inboundparcel/EditViewIS/";
import Inboundparcels from "../components/Inboundparcel/ListIS/";

// OUTBOUND SHIPMENT
import ListOS from "../components/OutboundShipment/ListOS/";
import EditViewOS from "../components/OutboundShipment/EditViewOS/";
// import Packinglist from "../components/OutboundShipment/Packinglist";

// PO-internal
import ListPOs from "../components/PO/ListPOs/";
import CreatePO from "../components/PO/InternalPO/CreatePO_v2/index";
// import EditViewPO from "../components/PO/InternalPO/EditViewPO/";
import Purchaseorder from "../components/PO/InternalPO/EditViewPO_v2";

// PRODUCT
import ProductsListPage from "../components/Product/ProductsList/";
import Newproduct from "../components/Product/NewProduct/";
import EditViewProduct from "../components/Product/EditViewProduct/";
import CreateNewPG from "../components/Product/Productgroup/CreateNewPG";
import CreateNewRP from "../components/Product/Relatedproducts/CreateNewPG";
import EditViewPG from "../components/Product/Productgroup/EditViewPG";
import EditViewRP from "../components/Product/Relatedproducts/EditViewPG";
import EditViewPromo from "../components/Product/Promos/EditView";
import CreatePromo from "../components/Product/Promos/CreateNewPG";
import ImportProductQuotations from '../components/Product/QuotationsFromExcel'

// FULFPRODUCT SETTINGS
// import Fulfilmentproduct from '../components/FulfilmentsProduct/ListPG'
// import CreateNewFulfilmentproduct from "../components/FulfilmentsProduct/CreateNewPG";
// import EditNewFulfilmentproduct from "../components/FulfilmentsProduct/EditViewPG"; 

// ORDER
import OrdersListPage from "../components/Order/ListOrders";
import CreateOrder from "../components/Order/CreateOrder/";
// import ViewOrder from "../components/Order/EditViewOrder";
import GenerateInvoice from "../components/Order/Invoice/GenerateInvoice";

// PICKER

import PickerOrdersV2 from "../components/Picking/V2";



// PACKER
import PackerList from "../components/Packer/PackerListPage";
// import PackerList from "../components/Packer/PackerListPage";
// import PackingView from "../components/Packer/PackerOrderPage";
import PackingView from "../components/Packer/PackerOrderPageV2";
// import ReturnedOrders from "../components/Packer/ReturnedOrders";
import BarcodeInput from "../components/Packer/Returnformv2.jsx";

// COURIER
import CourierList from "../components/Courier/CourierList";
import CourierPage from "../components/Courier/CourierPage";

// USER
import UserList from "../components/Users/UserList";
import CreateUser from "../components/Users/CreateUser";
import EditUser from "../components/Users/EditUser/index";
import UserProfile from "../components/UserProfile/";
import Onlineusers from "../components/Reports/Onlineusers";

// ROLES
import ListRoles from "../components/Roles/ListRoles";
import CreateRole from "../components/Roles/CreateRole";

// BCLIENT
import BCList from "../components/Businessclients/BCList";
import CreateBC from "../components/Businessclients/CreateBC";
import EditBC from "../components/Businessclients/EditBC";

// WAREHOUSES LIST
import WarehouseSettings from "../components/WarehouseSettings/index";


//QUOTATIONS
import Quotations from '../components/Quotes'
import ServiceTypes from '../components/ServiceTypes'

// BILLING
import Services from "../components/Billableservices/ServicesList";
import BillList from "../components/Billing/BillList";
import ViewBill from "../components/Billing/ViewBill/";
import CreateBill from "../components/Billing/CreateBill/";
import CreateBillRun from "../components/Billing/BillRun";
// import CreateCreditNote from "../components/Billing/CreditNote/CreateCreditNote";
// import ViewCreditNote from "../components/Billing/CreditNote/ViewCreditNote";
import GenereateBill from "../components/Billing/BillPdf/GenerateBill";

//INVOICING
import Invoices from '../components/Invoices'
import Viewinvoice from "../components/Invoices/ViewInvoice";


//REPORTS
import Stocksturnover from "../components/Reports/Stockturnover";
import Leadstock from "../components/Reports/Leadstock";
import Nogoodsreport from '../components/Reports/Nogoods'
import Finance       from '../components/Reports/Finance'
import Deliveryrate       from '../components/Reports/Deliveryrate'
import CreateTransfer from "../components/Reports/Finance/Transfers/CreateTransfer";
import ViewTransfer from "../components/Reports/Finance/Transfers/ViewTransfer";
import Lowstock from "../components/Reports/Lowstock";


//REFUNDS
import Refunds from '../components/Refund'

//BALANCES
import Accountbalances from '../components/Accountbalance'


import NotFoundPage from "../components/NotFoundPage";

//ERROR LIST
import Errorlist from "../components/Errorlogs";

import PopupWindow from "../components/PopupWindow";
import ExportWindow from "../components/ExportWindow";
import UploadWindow from "../components/UploadWindow";

import ImportQuotations from '../components/Quotes/QuotationsFromExcel'

// import Fileupload from '../components/Uploader/files'

// export const socket = io(`${process.env.REACT_APP_SOCKET}`);


//ORDER V2
import Order from '../components/Order/v2/Vieworder'

import Cashier from '../components/Cashier'

import Listcheckouts from '../components/Cashier/Listcheckouts'
import BarcodeScannerComponent from "../components/Driver/index.jsx";
import ExcelTemplateBuilder from "../components/ExcelTemplateBuilder";

import Dashboard from "../components/Dashboard";
import Barchart from '../components/Dashboard/barchart'
import Bar2periods from '../components/Dashboard/bar2periods'
import LineChart from '../components/Dashboard/products_chart'
import Linestockbalance from '../components/Dashboard/line_day_stock'
import Pieproductpercent from '../components/Dashboard/pie_productpercent'
import Pieproductstatus from '../components/Dashboard/pie_productstatus'
import Myhome from "../components/myhome";
import UploadCOD from "../components/UploadCOD";
import Upload_COST from "../components/UploadCosts";

class Main extends Component {
  

  componentDidUpdate() {
    if (this.props.errorMsg === "Please Login !!!") {
      this.props.logOutAction();
    }
  }

  render() {
    const {
      user,
      errorMsg,
      successMsg,
      signUpAction,
      signInAction,
      logOutAction,
      isAuthenticated,
      isLoading,
      removeError,
      removeSuccessMsg,
      currentUser,
      popupType,
    } = this.props;

    return (
      <div
      // className="admin" style={{minHeight:'2000px'}}
      >
        {/* <ReactNotification></ReactNotification> */}
        <PopupWindow>
          {popupType === "export" && <ExportWindow />}
          {popupType === "upload" && <UploadWindow />}
        </PopupWindow>
        <Notification
          type={"error"}
          trigger={errorMsg}
          removeNotification={removeError}
        />
        <Notification
          type="success"
          trigger={successMsg}
          removeNotification={removeSuccessMsg}
        />

        {/* <Suspense
          fallback={
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          }
        > */}
        <Switch>
          {/* <Route  exact /> */}
          <Route
            exact
            path="/signin"
            render={(props) => (
              <AuthForm
                signIn={true}
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                heading={"Welcome Back "}
                buttonText={"Log in"}
                onAuth={signInAction}
                logOutAction={logOutAction}
                timer_status={this.timer_status}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/signup"
            render={(props) => (
              <AuthForm
                signIn={false}
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                heading={"Join today."}
                buttonText={"Sign me up!"}
                onAuth={signUpAction}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <Home
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                logOutAction={logOutAction}
                isLoading={isLoading}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/myhome"
            render={(props) => <Myhome {...props} currentUser={currentUser} />}
          />
          {/* <Route
            exact
            path="/v2/orders/:id"
            render={(props) => <Order {...props} currentUser={currentUser} />}
          /> */}

          <Route
            exact
            path="/warehouse"
            render={(props) => <Stocks {...props} />}
          />
          {/* <Route
            exact
            path="/:warehouse_id/inventory/:sku/movement/:skip/:limit"
            render={(props) => <Inventorycard {...props} />}
          /> */}
          <Route
            exact
            path="/inventorycount"
            render={(props) => <InventoryCountList {...props} />}
          />
          {/* PRODUCTS && P-GROUPS */}
          <Route
            exact
            path="/products"
            render={(props) => <ProductsListPage {...props} />}
          />
          <Route
            exact
            path="/products/create/new"
            render={(props) => <Newproduct {...props} errorMsg={errorMsg} />}
          />
          <Route
            exact
            path="/products/quotations/import"
            render={(props) => (
              <ImportProductQuotations {...props} errorMsg={errorMsg} />
            )}
          />

          <Route
            exact
            path="/products/:id"
            render={(props) => <EditViewProduct {...props} />}
          />
          <Route
            exact
            path="/productgroup/create/new"
            render={(props) => <CreateNewPG {...props} />}
          />
          <Route
            exact
            path="/relatedproducts/create/new"
            render={(props) => <CreateNewRP {...props} />}
          />
          <Route
            exact
            path="/productgroup/:id"
            render={(props) => <EditViewPG {...props} />}
          />
          <Route
            exact
            path="/promos/:id"
            render={(props) => <EditViewPromo {...props} />}
          />
          <Route
            exact
            path="/promos/create/new"
            render={(props) => <CreatePromo {...props} />}
          />
          <Route
            exact
            path="/relatedproducts/:id"
            render={(props) => <EditViewRP {...props} />}
          />
          {/* Inbound Shipment */}
          <Route
            exact
            path="/shipments/inbound"
            render={(props) => <Inboundshipments {...props} />}
          />
          <Route
            exact
            path="/shipments/inbound/new"
            render={(props) => <CreateInboundShipment {...props} />}
          />
          <Route
            exact
            path="/shipments/inbound/:id"
            render={(props) => <EditViewInboundShipment {...props} />}
          />

          <Route
            exact
            path="/movetostoragelocation"
            render={(props) => <ChangeStockStorageLocation {...props} />}
          />

          <Route
            exact
            path="/parcels/inbound"
            render={(props) => <Inboundparcels {...props} />}
          />
          <Route
            exact
            path="/parcels/inbound/add/new"
            render={(props) => <Createinboudparcel {...props} />}
          />
          <Route
            exact
            path="/parcels/inbound/:id"
            render={(props) => <Inboundparcel {...props} />}
          />

          <Route
            exact
            path="/shipments/outbound"
            render={(props) => <ListOS {...props} />}
          />
          <Route
            exact
            path="/shipments/outbound/:id"
            render={(props) => <EditViewOS {...props} />}
          />

          <Route exact path="/pos" render={(props) => <ListPOs {...props} />} />

          <Route
            exact
            path="/pos/create/new"
            render={(props) => <CreatePO {...props} />}
          />

          <Route
            exact
            path="/pos/:id"
            render={(props) => <Purchaseorder {...props} />}
          />
          <Route
            exact
            path="/orders"
            render={(props) => (
              <OrdersListPage
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/orders/:id"
            render={(props) => (
              <Order {...props} currentUser={currentUser} />
              // <Vieworder
              //   currentUser={user.currentUser}
              //   isAuthenticated={isAuthenticated}
              //   isLoading={isLoading}
              //   {...props}
              // />
            )}
          />
          <Route
            exact
            path="/orders/create/new"
            render={(props) => (
              <CreateOrder
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/invoices"
            render={(props) => (
              <Invoices
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/invoices/:id/pdf"
            render={(props) => (
              <GenerateInvoice
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/invoices/:id"
            render={(props) => (
              <Viewinvoice
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/couriers/"
            render={(props) => (
              <CourierList
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/couriers/:id"
            render={(props) => (
              <CourierPage
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          {/* <CanViewPage path="/users"> */}
          <Route
            exact
            path="/users"
            render={(props) => (
              <UserList
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          {/* </CanViewPage>
          <CanViewPage path="/users/create/new"> */}
          <Route
            exact
            path="/users/create/new"
            render={(props) => (
              <CreateUser
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          {/* </CanViewPage>
          <CanViewPage path="/users/:id"> */}
          <Route
            exact
            path="/users/:id"
            render={(props) => (
              <EditUser
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/roles"
            render={(props) => (
              <ListRoles
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/roles/create/new"
            render={(props) => (
              <CreateRole
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/businessclients"
            render={(props) => (
              <BCList
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/businessclients/create/new"
            render={(props) => (
              <CreateBC
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/businessclients/:id"
            render={(props) => (
              <EditBC
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                {...props}
              />
            )}
          />

          {/* <Route
            exact
            path="/picker/orders"
            render={(props) => (
              <PickerOrders
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          /> */}
          <Route
            exact
            path="/picker/orders"
            render={(props) => (
              <PickerOrdersV2
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/packer/orders/"
            render={(props) => <PackerList {...props} />}
          />
          <Route
            exact
            path="/packer/orders/:id"
            render={(props) => (
              <PackingView {...props} currentUser={user.currentUser} />
            )}
          />

          <Route
            exact
            path="/packer/returns"
            render={(props) => <BarcodeInput {...props} />}
          />
          {/* <Route
            exact
            path="/packer/returns"
            render={(props) => <ReturnedOrders {...props} />}
          /> */}

          <Route
            exact
            path="/services"
            render={(props) => <Services {...props} />}
          />
          <Route
            exact
            path="/billing"
            render={(props) => <BillList {...props} />}
          />
          <Route
            exact
            path="/billing/manually"
            render={(props) => <CreateBill {...props} />}
          />
          <Route
            exact
            path="/billing/billrunservices"
            render={(props) => <CreateBillRun {...props} />}
          />
          <Route
            exact
            path="/billing/:id"
            render={(props) => <ViewBill {...props} />}
          />
          <Route
            exact
            path="/billing/:id/pdf"
            render={(props) => <GenereateBill {...props} />}
          />

          {/* <Route
            exact
            path="/creditnote/create"
            render={(props) => <CreateCreditNote {...props} />}
          /> */}
          {/* <Route
            exact
            path="/creditnote/:id"
            render={(props) => <ViewCreditNote {...props} />}
          /> */}
          {/* <Route
              exact
              path="/transfers"
              render={(props) => <ListTransfers {...props} />}
            /> */}
          <Route
            exact
            path="/reports/transfers/create"
            render={(props) => <CreateTransfer {...props} />}
          />
          <Route
            exact
            path="/transfers/:id"
            render={(props) => <ViewTransfer {...props} />}
          />
          {/* <Route
            exact
            path="/reports/finance/invoices"
            render={(props) => <InvoicesList {...props} />}
          /> */}

          <Route
            exact
            path="/reports/stockturnover"
            render={(props) => <Stocksturnover {...props} />}
          />
          <Route
            exact
            path="/reports/leadstock"
            render={(props) => <Leadstock {...props} />}
          />

          <Route
            exact
            path="/reports/nogoods"
            render={(props) => <Nogoodsreport {...props} />}
          />

          <Route
            exact
            path="/reports/deliveryrate"
            render={(props) => <Deliveryrate {...props} />}
          />

          <Route
            exact
            path="/reports/lowstocks"
            render={(props) => <Lowstock />}
          />

          <Route
            exact
            path="/reports/onlineusers"
            render={(props) => <Onlineusers {...props} />}
          />
          <Route
            exact
            path="/reports/finance"
            render={(props) => <Finance {...props} />}
          />

          <Route
            exact
            path="/settings/profile"
            render={(props) => (
              <UserProfile {...props} userId={currentUser.userId} />
            )}
          />

          <Route
            exact
            path="/settings/warehouses"
            render={(props) => (
              <WarehouseSettings {...props} userId={currentUser.userId} />
            )}
          />

          <Route
            exact
            path="/settings/warehouses/:id"
            render={(props) => (
              <Warehouse {...props} userId={currentUser.userId} />
            )}
          />

          <Route
            exact
            path="/health/errors"
            render={(props) => <Errorlist {...props} />}
          />

          <Route
            exact
            path="/quotations"
            render={(props) => <Quotations {...props} />}
          />

          <Route
            exact
            path="/quotations/import"
            render={(props) => <ImportQuotations {...props} />}
          />
          <Route
            exact
            path="/servicetypes"
            render={(props) => <ServiceTypes {...props} />}
          />
          <Route
            exact
            path="/refunds"
            render={(props) => <Refunds {...props} />}
          />
          {/* <Route
            exact
            path="/uploadfiles"
            render={(props) => <Fileupload {...props} />}
          /> */}

          <Route
            exact
            path="/accountbalances"
            render={(props) => (
              <Accountbalances {...props} currentUser={currentUser} />
            )}
          />

          {/* <Route
            exact
            path="/imagepreview"
            render={(props) => <Imagepreview />}
          /> */}
          <Route
            exact
            path="/checkout/create"
            render={(props) => <Cashier />}
          />
          <Route
            exact
            path="/checkout/list"
            render={(props) => <Listcheckouts />}
          />
          <Route
            exact
            path="/barcodescanner"
            render={(props) => <BarcodeScannerComponent />}
          />
          <Route
            exact
            path="/exceltemplatebuilder"
            render={(props) => <ExcelTemplateBuilder />}
          />

          <Route
            exact
            path="/dashboard"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Dashboard />}
          />
          <Route
            exact
            path="/dashboard/barchart"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Barchart />}
          />

          <Route
            exact
            path="/dashboard/bar2periods"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Bar2periods />}
          />

          <Route
            exact
            path="/dashboard/linechart"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <LineChart />}
          />
          <Route
            exact
            path="/dashboard/linestockbalance"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Linestockbalance />}
          />
          <Route
            exact
            path="/dashboard/pieproductpercent"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Pieproductpercent />}
          />
          <Route
            exact
            path="/dashboard/pieproductstatus"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Pieproductstatus />}
          />
          <Route
            exact
            path="/uploadcod"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <UploadCOD />}
          />
          <Route
            exact
            path="/uploadcost"
            // eslint-disable-next-line react/jsx-pascal-case
            render={(props) => <Upload_COST />}
          />

          <Route
            exact
            path=""
            render={(props) => <NotFoundPage {...props} />}
          />
        </Switch>
        {/* </Suspense> */}
        {/* <div>Footer</div>       */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  username:        state.user.currentUser.username,
  currentUser:     state.user.currentUser,
  errorMsg:         state.notifications.errorMsg,
  successMsg: state.notifications.successMsg,
  popupType: state.notifications.popupType,
  user: state.user,
});
export default connect(mapStateToProps, {
  signUpAction,
  signInAction,
  logOutAction,
  getUsers,
  removeError,
  removeSuccessMsg,
})(Main);
