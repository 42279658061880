import React, { useState } from "react";
import {
  Table,
  Loader,
  Header,
  Checkbox,
  Label,
  Button,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Modal from "react-responsive-modal";
import ItemsList from "../CreateBill/ItemsList";

const status = { true: "Paid", false: "Not paid - In due" };
const label = { true: "green", false: "yellow" };

function BillsTable({
  currentPage,
  itemsPerPage,
  bills = [],
  isLoading,
  handleCheck,
}) {
  // View credit note items Modal
  const [isItemsModalOpen, setItemsModalOpen] = useState(false);
  const [items, setItems] = useState([]);

  const closeItemsModal = () => {
    setItemsModalOpen(false);
  };
  const openItemsModal = (items) => {
    setItems(items);
    setItemsModalOpen(true);
  };

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!isLoading && !bills.length)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Header as="h4" textAlign="center">
          No bills found
        </Header>
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>Issuer</Table.HeaderCell>
      <Table.HeaderCell>Businessclient</Table.HeaderCell>
      <Table.HeaderCell>Bill Nr.</Table.HeaderCell>
      <Table.HeaderCell>Dates</Table.HeaderCell>
      <Table.HeaderCell>Total</Table.HeaderCell>
      <Table.HeaderCell>Currency</Table.HeaderCell>
      {/* <Table.HeaderCell>Country</Table.HeaderCell> */}
      <Table.HeaderCell>Items</Table.HeaderCell>
      <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = bills.map(
    (
      {
        _id,
        businessclient,
        issuer,
        items,
        dates,
        sequenceNumber,
        currency,
        period,
        totals,
        isPaid,
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell><Link to={`/businessclients/${issuer._id}`}>{issuer && issuer.name}</Link></Table.Cell>
        <Table.Cell><Link to={`/businessclients/${businessclient._id}`}>{businessclient && businessclient.name}</Link></Table.Cell>
        <Table.Cell>
          <Link to={`/billing/${_id}`}>
            {sequenceNumber ? sequenceNumber : "na"}
          </Link>{" "}
          <br></br>
          {period && period.start && period.end && (
            <>
              <Moment utc format="DD/MM/YYYY">
                {period.start}
              </Moment>
              -
              <Moment utc format="DD/MM/YYYY">
                {period.end}
              </Moment>
            </>
          )}
        </Table.Cell>
        <Table.Cell>
          <>
            <p className="small--text">
              Issued:{" "}
              <Moment utc format="DD-MM-YY HH:mm">
                {dates && dates.issuedDate}
              </Moment>
            </p>
            <p className="small--text">
              Tax date:{" "}
              <Moment utc format="DD-MM-YY HH:mm">
                {dates && dates.taxDate}
              </Moment>
            </p>
            <p className="small--text">
              Due date:{" "}
              <Moment utc format="DD-MM-YY HH:mm">
                {dates && dates.dueDate}
              </Moment>
            </p>
          </>
        </Table.Cell>
        <Table.Cell>
          <>
            <p className="small--text">
              VAT total: {totals && totals.VAT_total.toFixed(2)}
            </p>
            <p className="small--text">
              NETTO total: {totals && totals.NETTO_total.toFixed(2)}
            </p>
            <p className="small--text">
              BRUTTO total: {totals && totals.BRUTTO_total.toFixed(2)}
            </p>
          </>
        </Table.Cell>
        <Table.Cell>{currency}</Table.Cell>
        {/* <Table.Cell>{country}</Table.Cell> */}
        {/* <Table.Cell>
          <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
        </Table.Cell> */}
        <Table.Cell>
          <Button
            primary
            compact
            basic
            size="tiny"
            onClick={() => openItemsModal(items)}
            content="View"
          />
        </Table.Cell>
        <Table.Cell>
          <Label color={label[isPaid]}>{status[isPaid]}</Label>
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            label="Is paid"
            checked={isPaid}
            onChange={(e, { checked }) => handleCheck({ _id, checked })}
          />
        </Table.Cell>
      </Table.Row>
    )
  );

  return (
    <Segment>
      <Table unstackable basic="very">
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>

      <Modal
        open={isItemsModalOpen}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={closeItemsModal}
        center
        classNames={{ modal: "" }}
      >
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            paddingTop: "1.6em",
            paddingRight: "0.7em",
          }}
        >
          <ItemsList items={items} />
        </div>
      </Modal>
    </Segment>
  );
}

export default BillsTable;
