import React, { useState } from "react";
import { Icon, Button, Header, Segment, Form, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Orderscope from '../scope'
import BusinessclientFilter from "../../Filters/BusinessclientFilter";

import { createOrder } from "../../../actions/orders";
import CanViewPage from "../../../role-based-access/CanViewPage";
import CountryFilter from "../../Filters/CountryFilter";

import {Alert} from 'rsuite'

const CreateOrder = (props) => {

  const [showScopeList, setShowScopelist] = useState(false)

  const [query, setQuery] = useState({ scope:'client_claim'})





  return (
    <CanViewPage path="/orders/create/new">
      <Orderscope
        
        showScopeList={showScopeList}
        setShowScopelist={setShowScopelist}
        setScope={(value) => setQuery({ ...query, scope: value })}
        orderScope={query['scope']}
        onClickFunction={() => {

          createOrder(query, props.history).then(resp=>console.log(resp)).catch(error=>Alert.error(error))

        }}
      ></Orderscope>

      <div className="a-wrapper page-wrapper create-order">
        {/* {validationErrMsg && (
            <div className="alert alert-danger">{validationErrMsg}</div>
          )} */}
        <div className="flex_spaceBetween vertical-align margin-bottom">
          {/* <Header className="form__header" as="h3">
            Create new order
          </Header> */}
          <Link to="/orders">
            <Icon name="arrow left" />
            Orders list
          </Link>

          
        </div>
        {/* <Divider /> */}

        <Header as="h3">New Order</Header>
        <Segment padded>

          <Form className="form-wrapper">
            <Header className="form__sub-header">Business client:</Header>
            <Divider />
            {/* <Divider horizontal className="create-form__sub-header">
          Business client
        </Divider> */}
            <Form.Group widths={3}>
              <div className="flex--column form__client-field">
                <label className="client-label">Client:</label>
                <BusinessclientFilter
                  useFilter={false}
                  name="businessclient"
                  handleChange={(value) => setQuery({ ...query, businessclient: value })}
                />
              </div>
              <Form.Input
                name="clientOrderNr"
                label="Client Order Nr:"
                placeholder="order number..."
                width={4}

                // value={["clientOrderNr"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              />
            </Form.Group>
            <Header className="form__sub-header">Client Info:</Header>
            <Divider />
            {/* <Divider horizontal className="create-form__sub-header">
          Client Info
        </Divider> */}

            <Form.Group widths={3}>
              <Form.Input
                autoComplete="nope"
                name="shipping.shipTo.client.firstName"
                label="First Name"
                placeholder="First Name"
                error={!query["shipping.shipTo.client.firstName"]}

                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              />
              <Form.Input
                autoComplete="nope"
                name="shipping.shipTo.client.lastName"
                label="Last Name"
                placeholder="Last Name"
                // value={["shipping.shipTo.client.lastName"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              />
              <Form.Input
                required
                autoComplete="nope"
                error={!query["shipping.shipTo.phone"]}

                name="shipping.shipTo.phone"
                label="Phone"
                placeholder="Phone"
                // value={["shipping.shipTo.client.phone"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              />
            </Form.Group>

            <Header className="form__sub-header">Shipping Adress:</Header>
            <Divider />
            {/* <Divider horizontal className="create-form__sub-header">
          Shipping Adress
        </Divider> */}
            <Form.Group>
              <Form.Field required>
                <label>Country</label>
                <CountryFilter
                  useFilter={false}
                  handleChange={(value) => setQuery({ ...query, 'shipping.shipTo.country': value })}
                />
              </Form.Field>

              <Form.Input
                autoComplete="nope"
                name="shipping.shipTo.province"
                label="Province"
                placeholder="Province"
                width={6}
                // value={["shipping.shipTo.province"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              ></Form.Input>
              <Form.Input
                autoComplete="nope"
                required
                error={!query["shipping.shipTo.city"]}
                name="shipping.shipTo.city"
                label="City"
                placeholder="City"
                width={6}
                // value={["shipping.shipTo.city"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              ></Form.Input>
            </Form.Group>

            <Form.Group>
              <Form.Input
                autoComplete="nope"
                required
                error={!query["shipping.shipTo.street"]}
                label="Street"
                placeholder="street"
                name="shipping.shipTo.street"
                width={11}
                // value={["shipping.shipTo.street"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              />
              <Form.Input
                autoComplete="nope"
                label="Nr"
                placeholder="Nr"
                name="shipping.shipTo.streetNr"
                width={2}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              // value={["shipping.shipTo.streetNr"]}
              />
              <Form.Input
                autoComplete="nope"
                required
                error={!query["shipping.shipTo.postcode"]}
                name="shipping.shipTo.postcode"
                label="Post Code"
                placeholder="Post Code"
                width={5}
                // value={["shipping.shipTo.postcode"]}
                onChange={(e, { name, value }) => setQuery({ ...query, [name]: value })}
              ></Form.Input>
            </Form.Group>

          </Form>
        </Segment>


        <div className="flex--right mt--2">
          <Button
            color="teal"
            size="large"
            onClick={() => setShowScopelist(true)}
          >
            Create Order
          </Button>

          <Link to="/orders">
            <Button size="large">Cancel</Button>
          </Link>
        </div>


      </div>
    </CanViewPage>
  );
}



export default (CreateOrder);
