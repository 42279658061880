import React, { useEffect,useState } from "react";
import { Header } from "semantic-ui-react";
import {
  getInventoryCounts,
} from "../../../actions/inventory";

import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import InventoryCountTable from "./InventoryCountTable";

function InventoryCountList(props) {
  
  const [isLoading,setLoading] = useState(false)
  const [total,setTotal]=useState(0)
  const [currentPage,setCurrentPage] = useState(1)
  const [itemsPerPage,setItemsPerPage] = useState(10)
  const [inventoryCounts,setCounts] = useState(0)

  
  useEffect(() => {
    setLoading(true)
    getInventoryCounts({currentPage,itemsPerPage}).then(resp=>{
    setLoading(false)
    setCounts(resp.list)
    setTotal(resp.total)
  })}, [currentPage, itemsPerPage]);

  return (
    <main className="page-wrapper a-wrapper">
      <Header>Inventory Count</Header>
      <div className="flex--right">
        <ItemsPerPage
          total={total}
          value={itemsPerPage}
          setItemsPerPage={(value) =>{
            setItemsPerPage(value)
            setCurrentPage(1)
          }
          
          }
        />
      </div>
      <InventoryCountTable
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        isLoading={isLoading}
        inventoryCounts={inventoryCounts}
      />
      {total > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          total={total}
          setCurrentPage={(page)=>{setCurrentPage(page)}}
        />
      )}
    </main>
  );
}


export default (
  InventoryCountList
);
