import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Header,
  Dropdown,
  Checkbox,
  Table,
} from "semantic-ui-react";
import CountryFilter from "../../../Filters/CountryFilter";
import BusinessclientFilter from "../../../Filters/BusinessclientFilter";
import { getBClientIds, addBilling } from "../../../../actions/businessclient";
import { getBillingCycles } from "../../../../actions/enums";
import CurrencyFilter from "../../../Filters/CurrencyFilter";

function BillingModal({ businessclient, handleClose, handleGetAll }) {
  const bill_types = ["Fulfilment", "Fixed_Reccuring"]

  const [issuer, setIssuer] = useState();
  const [issuerId, setIssuerTaxId] = useState();
  const [issuerIds, setissuerIds] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState([]);
  const [businessclientId, setBussinesclientId] = useState();
  const [businessclientIds, setBussinesclientIds] = useState([]);
  const [vat, setVat] = useState();
  const [country, setCountry] = useState();
  const [currency, setCurrency] = useState();
  const [daysToDue, setDuedays] = useState(14);
  const [categories, setCategory] = useState(["shipping", "warehouse"]);
  const [bill_type, setBillType] = useState(bill_types[0]);
  const [cycles, setCycles] = useState([]);
  const [cycle, setCycle] = useState("Monthly");

  const [items, setItems] = useState([])

  const handleCreate = () => {
    addBilling({
      id: businessclient._id,
      query: {
        issuer,
        issuerId,
        businessclient: businessclient._id,
        businessclientId,
        vat,
        country,
        currency,
        daysToDue,
        clients,
        category: categories,
        cycle,
        bill_type,
        items
      },
    }).then((resp) => {
      handleGetAll();
      handleClose();
    });
  };

  const handleChangeCategory = (e, { checked, name }) => {
    if (checked) {
      setCategory([...categories, name]);
    } else {
      //find index
      const index = categories.indexOf(name);
      console.log(index);
      //remove item
      const newArr = categories.filter((item) => item !== name);
      console.log(newArr);

      setCategory(newArr);
    }
  };

  const Items = () => {
    const [description, setDescription] = useState()
    const [quantity, setQuantity] = useState(1)
    const [netto_price, setPrice] = useState(0)


    return (<><th>Item </th>
      <th><div>
        <Input placeholder='Description' fluid value={description} onChange={(e, { value }) => setDescription(value)}></Input>
        <Input placeholder='quantity' value={quantity} onChange={(e, { value }) => setQuantity(value)}></Input>
        <Input placeholder='netto value' value={netto_price} onChange={(e, { value }) => setPrice(value)}></Input>

      </div></th>
      <th ><Button onClick={() => {
        setItems([...items, { description, quantity, netto_price }])

      }}>Add</Button></th></>)
  }

  useEffect(() => {
    if (issuer) getBClientIds(issuer).then((resp) => setissuerIds(resp));
  }, [issuer]);

  useEffect(() => {
    if (businessclient)
      getBClientIds(businessclient._id).then((resp) =>
        setBussinesclientIds(resp)
      );
  }, [businessclient]);

  useEffect(() => {
    getBillingCycles().then((enums) => setCycles(enums));
  }, []);

  useEffect(() => { console.log(items) }, [items])
  return (
    <section style={{ width: "700px" }}>
      <Header as="h2">Add New Billing Setting</Header>

      <Table basic style={{ textAlign: 'left', }}>
        <tr>
          <th style={{ width: '150px' }}>
            <h5>Bill Receiver</h5>
          </th>
          <th style={{ width: '150px' }}>
            {" "}
            <div>{businessclient.name}</div>
          </th>
          <th style={{ width: '100px' }}>
            <label>TaxId</label>
          </th>
          <th style={{ width: '160px' }}>

            <Dropdown
              options={businessclientIds.map((item) => ({
                value: item._id,
                text: `${item.country} ${item.taxid}`,
                key: item._id,
              }))}
              onChange={(e, { value }) => setBussinesclientId(value)}
            ></Dropdown>
          </th>
        </tr>
        <tr>
          <th>
            {" "}
            <h5>Bill Issuer</h5>
          </th>
          <th>
            {" "}
            <BusinessclientFilter
              fluid
              useFilter={false}
              placeholder="issuer"
              value={issuer}
              handleChange={(value) => setIssuer(value)}
            />
          </th>
          <th>
            {" "}
            <label>Issuer TaxId</label>
          </th>
          <th>
            {" "}
            <Dropdown
              options={issuerIds.map((item) => ({
                value: item._id,
                text: `${item.country} ${item.taxid}`,
                key: item._id,
              }))}
              onChange={(e, { value }) => setIssuerTaxId(value)}
            ></Dropdown>
          </th>
        </tr>

        <tr>
          <th>
            {" "}
            <label>
              Country:{" "}
              <span className="new-ticket-form__required-symbol">*</span>
            </label>
          </th>
          <th>
            {" "}
            <CountryFilter
              useFilter={false}
              value={country}
              handleChange={(value) => setCountry(value)}
            />
          </th>
        </tr>
        <tr>
          <th>Currency</th>
          <th><CurrencyFilter handleChange={(value)=>setCurrency(value)}></CurrencyFilter></th>
        </tr>
        <tr>
          <th>
            <label>Days to due |</label>
          </th>
          <th>
            <Input
              style={{ marginLeft: "0px", width: "85px" }}
              type="number"
              min="0"
              value={daysToDue}
              name="daysToDue"
              onChange={(e, { value }) => setDuedays(value)}
            />
          </th>
        </tr>
        <tr>
          <th>
            {" "}
            <label>VAT (%)</label>
          </th>
          <th>
            {" "}
            <Input
              style={{ marginLeft: "0px", width: "85px" }}
              type="number"
              min="0"
              value={vat}
              name="vat"
              onChange={(e, { value }) => setVat(value)}
            />
          </th>
        </tr>
        <tr>
          <th>
            {" "}
            <label>Billing Cycle</label>
          </th>
          <th>
            <Dropdown
              value={cycle}
              options={cycles.map((item) => ({
                value: item,
                text: item,
                key: item,
              }))}
              onChange={(e, { value }) => setCycle(value)}
            ></Dropdown>
          </th>
        </tr>
        <tr>
          <th>
            {" "}
            <label>Billing Type</label>
          </th>
          <th>
            <Dropdown
              value={bill_type}
              options={bill_types.map((item) => ({
                value: item,
                text: item,
                key: item,
              }))}
              onChange={(e, { value }) => {
                setBillType(value)
                if (bill_type === 'Fulfilment') { setCategory(['other']) }
                else setCategory(["shipping", "warehouse"])
              }}
            ></Dropdown>
          </th>
        </tr>

        {bill_type === 'Fixed_Reccuring' && <>
          <tr>
            <Items></Items>
          </tr></>}

        {items.map((item, index) => (<tr>
          <th>{index + 1}/ {item.description}</th>
          <th>{item.quantity}</th>
          <th>{item.netto_price}</th></tr>))}

        {bill_type === "Fulfilment" && <>  <tr>
          <th>Services Of</th>
          <th>
            {" "}
            <BusinessclientFilter
              useFilter={false}
              placeholder="client services"
              value={client}
              handleChange={(value) => {
                // console.log(data)
                setClient(value);
              }}
            ></BusinessclientFilter>
          </th>
          <th>
            {" "}
            <Button onClick={() => setClients([...clients, client])}>
              Add
            </Button>
          </th>
          <th></th>
        </tr>
          <tr>
            <th>Added Clients</th>
            <th>
              {" "}
              <table>
                {clients.map((client) => (
                  <tr>
                    <td>Id</td>
                    <td>{client}</td>
                  </tr>
                ))}
              </table>
            </th>
            <th></th>
            <th></th>
          </tr></>}
      </Table>

      <div className="flex--between">
        <section id="caterries">
          <Checkbox
            style={{ marginRight: "10px" }}
            name="warehouse"
            label="Warehouse"
            checked={categories.includes("warehouse")}
            onChange={handleChangeCategory}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: "10px" }}
            name="shipping"
            label="Shipping"
            checked={categories.includes("shipping")}
            onChange={handleChangeCategory}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: "10px" }}
            name="finance"
            label="Finance"
            checked={categories.includes("finance")}
            onChange={handleChangeCategory}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: "10px" }}
            name="goods"
            label="Goods"
            checked={categories.includes("goods")}
            onChange={handleChangeCategory}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: "10px" }}
            name="callcenter"
            label="Call Center"
            checked={categories.includes("callcenter")}
            onChange={handleChangeCategory}
          ></Checkbox>
          <Checkbox
            style={{ marginRight: "10px" }}
            name="other"
            label="Other"
            checked={categories.includes("other")}
            onChange={handleChangeCategory}
          ></Checkbox>
        </section>

        <div>
          <div></div>
        </div>
      </div>

      <Button
        floated="right"
        primary
        content="Create"
        onClick={() => {
          handleCreate();
        }}
      />
      <Button floated="right" content="Cancel" onClick={handleClose} />
    </section>
  );
}

export default BillingModal;



