import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { getProductListV2 } from "../../actions/product";
import { useUpdateContext } from "../../context/UpdateContext";

function ProductFilterList({
  fluid = false,
  client = null,
  style = {
    margin: "2px",
    minWidth: "330px",
    maxWidth: "360px",
    border: "none !important",
    color: "#3F72AF !important",
  },
  placeholder = "product...",
  selectOnBlur = false,
  closeOnChange = true,
  disabled = false,
  multiple = false,
  clearable = true,
  className,
  useFilter = true,
  handleChange,
  value,
}) {
  const { handleProductChange, product, businessclient,setDropList } =
    useUpdateContext();

  const [searchTerm, setSearch] = useState(null);
  const [productList, setList] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    let useClient = businessclient;
    if (client) useClient = client;

    if (!useClient) {
      setSearch(null);
    }

    if (localStorage.productList && !searchTerm) {
      const list = JSON.parse(localStorage.getItem("productList") || "[]");
      // setDropList({ name: "productList", list });
      setList(list);
    } else {
      if (useClient) {
        setIsloading(true);
        getProductListV2({ searchTerm, businessclient: useClient })
          .then((data) => {
            setIsloading(false);
            setList(data.list);
            setDropList({
              name: "productList",
              list: JSON.stringify(data.list),
            });
          })
          .catch((error) => console.log(error.message));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessclient, searchTerm]);

  return (
    <Dropdown
      loading={isLoading}
      clearable={clearable}
      multiple={multiple}
      style={style}
      selection={true}
      fluid={fluid}
      disabled={disabled || (!businessclient&&useFilter&&!client)}
      placeholder={placeholder}
      selectOnBlur={selectOnBlur}
      closeOnChange={closeOnChange}
      className={className}
      search
      onSearchChange={(e, data) => setSearch(data.searchQuery)}
      label="Product"
      name="product"
      value={useFilter ? product : value}
      options={productList.map((product) => ({
        value: product._id,
        text: `${product.name}- [sku:${product.codes.sku}]`,
        key: product._id,
      }))}
      // loading={products.length == 0}
      onChange={(e, { name, value }) => {
        if (useFilter) handleProductChange(value);
        else handleChange(value);
      }}
    />
  );
}

export default ProductFilterList;
