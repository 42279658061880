import React from 'react'
import { Divider, Header, Checkbox, TextArea,Button } from 'semantic-ui-react'

import Modal from "react-responsive-modal";

const Orderscope = ({ showScopeList, setShowScopelist, orderScope, setScope,onClickFunction })=>{

    return (<div>

        <Modal
            open={showScopeList}
            showCloseIcon={false}
            closeOnEsc={true}
            onClose={() => setShowScopelist(false)}
            center
            classNames={{
                modal: "",
            }}
        >
            <Header as="h4">Order Scope</Header>
            <div className="flex--column" style={{ minWidth: "220px" }}>
                <Checkbox
                    radio
                    style={{ marginBottom: "5px" }}
                    label="Client Claim"
                    name="client_claim"
                    checked={orderScope === "client_claim"}
                    onChange={(e, data) => setScope("client_claim")}
                ></Checkbox>

                <Checkbox
                    radio
                    style={{ marginBottom: "5px" }}
                    label="Order Not Delivered"
                    name="not_delivered_repeat"
                    checked={orderScope === "not_delivered_repeat"}
                    onChange={(e, data) => setScope("not_delivered_repeat")}
                ></Checkbox>

                <Checkbox
                    radio
                    style={{ marginBottom: "5px" }}
                    label="Other"
                    name="other'"
                    checked={!['client_claim', 'not_delivered_repeat'].includes(orderScope)}
                    onChange={(e, data) => setScope("other")}
                ></Checkbox>
            </div>

            <Divider></Divider>

            {!['client_claim', 'not_delivered_repeat'].includes(orderScope) && <TextArea
                style={{ width: "100%" }}
                placeholder="insert order scope"

                value={orderScope}
                onChange={(e, { value }) => setScope(value)}
            ></TextArea>}


            <div style={{ marginTop: "2.7em" }} className="right--text"></div>
            <Button fluid color='green' onClick={() => onClickFunction()}>Create Order</Button>
        </Modal>

    </div>)
}


export default Orderscope