import React, { useState, useEffect } from "react";
import { Table, Button, Divider, Segment } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { courierCodUpload } from "../../actions/courier_cod_upload";
import { Alert } from "rsuite";
import Newteamplate from "./newtemplate";
import ExportButton from "./ExportButton";

function getRowStyle({ row, column, template }) {
  if (template.title_row == row + 1) return { backgroundColor: "#7fbada" };

  //highlight id
  if (column + 1 == template.look_up_key_cln && template.title_row < row + 1)
    return { backgroundColor: "#ffda37" };

  //highlight amount
  if (column + 1 == template.amount_cln && template.title_row < row + 1)
    return { backgroundColor: "#ff2c6c" };
}
const initResp = {
  count: 0,
  notFoundCount: 0,
  notFoundList: [],
}

const initTemplate = {
  look_up_key_cln: 1,
  look_up_key: "shipping.trackingId",
  amount_cln: 1,
  dataStartRow: 1,
  title_row: 1,
}

function UploadCOD(props) {
  const [courier, setCourier] = useState()
  const [template, setTemplate] = useState(initTemplate);
  const [rawData, setRawdata] = useState();
  const [visualizedData, setVisualizedData] = useState([]);
  const [uploadedData, setData] = useState([]);
  const [columnTotal, setColumnTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false)
  const [uploadResponse, setUplaodResponse] = useState();

  useEffect(() => {
    setUplaodResponse(null)
    if (rawData) {
      let template_data = rawData.map((row) => [
        row[template.look_up_key_cln - 1],
        row[template.amount_cln - 1],
      ]);

      setData(template_data);
    }
  }, [rawData, template]);

  // console.log("2.data to upload:", uploadedData[1]);

  useEffect(() => {

    if (rawData) {
      let total = 0;
      uploadedData.map((row) => {
        // console.log("row:", parseFloat(row[columnIndex]));
        total = total + (parseFloat(row[1]) || 0);
      });
      setColumnTotal(total);
    }
  }, [rawData, uploadedData]);

  const onDrop = (acceptedFiles) => {
    setLoading(true);
    setVisualizedData([]);
    const reader = new FileReader();

    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      setRawdata(jsonData);
      // console.log(jsonData);
      // Display first 5 rows
      setVisualizedData(jsonData.slice(0, 5));
      setLoading(false);
    };

    reader.readAsBinaryString(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
  });

  const handleUploadData = () => {
    setUploading(true)
    courierCodUpload({
      courier,
      data: uploadedData,
      template: template,
    })
      .then((resp) => {

        setUplaodResponse(resp)
        setUploading(false)
      })
      .catch((error) => {
        setUploading(false)
        Alert.error(error)
      });
  };

  return (
    <div className="App"
      style={{ marginTop: "20px", marginLeft: "30px", maxWidth: "1200px" }}>
      <Segment>
        <h1>COD Reports Uploader</h1>
        <div
          {...getRootProps()}
          style={{ border: "2px dashed #ccc", padding: "20px", margin: "20px" }}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop an Excel file here, or click to select file</p>
        </div>
      </Segment>
      {loading && <div>Loading table...</div>}
      {visualizedData.length > 0 && (
        <div>
          <Divider></Divider>
          <Newteamplate
            courier={courier}
            setCourier={setCourier}
            template={template}
            setTemplate={setTemplate}
          ></Newteamplate>

          <div id="courier template"></div>

          <Divider></Divider>
          <Segment>
            <h2>Preview Document 5 Rows:</h2>

            <Table>

              <tbody>
                {visualizedData.map((row, i) => (
                  <tr key={i}>
                    {row.map((cell, j) => (
                      <td
                        style={getRowStyle({ template, row: i, column: j })}
                        key={j}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Segment>
          {template && rawData && (<div style={{ display: 'flex', justifyContent: 'center' }}>
            <table style={{ textAlign: 'left', width: '500px' }}>
              <tr>
                <th> <h4>
                  Total of Column "
                  {rawData[template.title_row - 1][template.amount_cln - 1]}
                  ":
                </h4></th>
                <th><h4>{columnTotal}</h4></th>
              </tr>
              <tr>
                <th><h4>Total Lines:</h4></th>
                <th><h4>{rawData.length - 1}</h4></th>
              </tr>

            </table>

          </div>

          )}
        </div>
      )}
      <Divider />

      <Button
        // loading={uploading}
        disabled={uploading}
        color='green'
        onClick={() => handleUploadData()}
        style={{ width: '300px' }}>{!uploading ? `Upload` : `uploading...`}</Button>
      <Divider />
      <Segment loading={uploading} style={{ minHeight: "200px" }}>

        {!uploadResponse && <h1>Upload Results</h1>}
        {uploadResponse && <div>
          <p>Updated Orders: {uploadResponse.count}</p>
          <p>Not Found Orders: {uploadResponse.notFoundList.length}</p>
          {uploadResponse.notFoundList.length > 0 && (
            <ExportButton
              key_name={template.look_up_key}
              style={{ marginBottom: "10px" }}
              data={uploadResponse.notFoundList}
              filename={"Missing Ids"}
            ></ExportButton>
          )}
        </div>}



      </Segment>
    </div>
  );
}

export default UploadCOD;
